<template>
  <div
    class="interior-office-item w-full flex flex-col"
    @click.stop="goToDetail"
  >
    <div
      class="image relative w-full"
      :style="{
        backgroundImage: image ? `url(${image})` : '',
      }"
    >
      <div v-if="isLoggedIn" @click.stop="onFavorite">
        <eva-icon
          class="absolute"
          :name="isFavorite ? 'star' : 'star-outline'"
          :width="40"
          :height="40"
          :fill="isFavorite ? '#283cf0' : '#fff'"
        />
      </div>
    </div>

    <div class="interior-office-item-header">
      <div>
        <p class="place">{{ address }}</p>
      </div>

      <h3>{{ data.title }}</h3>
    </div>

    <div
      class="interior-office-item-button-group flex flex-row items-center justify-between"
    >
      <button class="flex flex-row items-center justify-center compatition">
        <img src="@/assets/portfolio.png" alt="portfolio" />포트폴리오
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utils from "@/utils";
import services from "@/services";

export default {
  name: "InteriorOfficeItem",
  props: {
    data: Object,
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  mounted() {
    this.isFavorite = !!this.subscribe;
  },
  computed: {
    image() {
      const images = utils.getInteriorImages(this.data);
      if (images.length > 0) {
        return images[0];
      }
      return "";
    },
    subscribe() {
      const { hasUserSubscribeInteriorOffices } = this.data;
      if (hasUserSubscribeInteriorOffices) {
        return hasUserSubscribeInteriorOffices.find(
          (s) => s.userId === this.userId
        );
      }
      return null;
    },
    address() {
      const { addr } = this.data;
      const splited = addr.split(" ");
      if (splited.length > 1) {
        return `${splited[0]} ${splited[1]} 위치`;
      } else if (splited.length === 1) {
        return `${splited[0]} 위치`;
      }
      return "";
    },
    ...mapState("user", ["userId", "userType", "isLoggedIn"]),
  },
  methods: {
    onFavorite() {
      if (this.isFavorite) {
        this.removeFavorite();
      } else {
        this.addFavorite();
      }
    },
    async addFavorite() {
      const success = await services.interiorAddSubscribe(
        this.userId,
        this.data.id
      );
      if (success) {
        this.isFavorite = true;
        this.$toast.open("찜이 되었습니다");
      } else {
        this.$toast.open({ message: "문제가 발생했습니다", type: "error" });
      }
    },
    async removeFavorite() {
      const success = await services.interiorRemoveSubscribe(this.data.id);
      if (success) {
        this.isFavorite = false;
        this.$toast.open("찜해제 되었습니다");
      } else {
        this.$toast.open({ message: "문제가 발생했습니다", type: "error" });
      }
    },
    goToDetail() {
      this.$router.push({
        name: "InteriorOfficeDetail",
        params: { id: this.data.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.interior-office-item {
  cursor: pointer;
  margin-bottom: 50px;

  .image {
    height: 305px;
    background-color: #ddd;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    i {
      top: 10px;
      right: 10px;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.8px;
    text-align: left;
    color: #222;
  }

  .place {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    color: #7ca3aa;
  }
}

.interior-office-item-header {
  margin-top: 15px;
  margin-bottom: 15px;
}

.interior-office-item-button-group {
  button {
    flex: 1;
    height: 50px;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.64px;

    img {
      margin-right: 7px;
      width: 20px;
      height: 20px;
    }

    &:first-child {
      margin-right: 8px;
    }

    &.compatition {
      background-color: #293bf0;
    }

    &.contact {
      background-color: #c1c1c1;
    }
  }
}

@media (max-width: 639px) {
  .interior-office-item {
    margin-bottom: 20px;
    .image {
      width: 200px;
      height: 150px;
      margin: 0 auto;
    }
    .place {
      font-size: 14px;
    }
    h3 {
      font-size: 15px;
    }
  }
  .interior-office-item-header {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .interior-office-item-button-group {
    button {
      height: 35px;
      font-size: 12px;
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}
</style>
